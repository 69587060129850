import React from 'react'

const Header = () => {
    return (
        <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
            <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                <h1 className="font-semibold font-rammetto lg:text-5xl sm:text-4xl text-2xl pb-4">SAP Career Guide</h1>
                <p className="lg:text-2xl sm:text-lg text-sm">A beginner’s manual on SAP careers for students and professionals</p>
            </div>
        </section>
    )
}

export default Header
