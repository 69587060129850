import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogItem from "./BlogItem"
import Newsletter from "../Newsletter";

const Blog = () => {

    const articles = useStaticQuery(graphql`
    {
        allMarkdownRemark {
        nodes {
            frontmatter {
                title
                slug
                excerpt
            }
            html
            }
        }
    }
    `)

    const allArticles = articles.allMarkdownRemark.nodes.map((item, index) => (
        <BlogItem
        key={index}
        slug={item.frontmatter.slug}
        alt={item.frontmatter.title}
        title={item.frontmatter.title}
        excerpt={item.frontmatter.excerpt}
        />
    ))
    return (
        <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap">
            <div className="w-full">
                <div className="w-full py-10">
                    {allArticles}
                    <Newsletter></Newsletter>
                </div>
            </div>
        </div>
    )
}

export default Blog
